import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
// import ServicesHome from "../Services/ServicesHome_5";
// import required modules
import { EffectCards, Pagination, Navigation } from 'swiper/modules';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function ContentServicesHome() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div className='bgCountent' style={{ backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/27527-pizarra.jpg?alt=media&token=4a16343c-e267-43a2-b5ce-0f5ad4925461")` }}>
      <img
        src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/image%20(5).png?alt=media&token=c9af34df-44bf-4595-909a-86f0c9ab5d30"}
        alt='no found'
        loading='lazy'
        className='absolute top-0 left-0 w-[100%] h-[5%]'
      />

      <img
        src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/image%20(6).png?alt=media&token=89428890-d8bb-4227-8838-dbffb470c309"}
        alt='no found'
        loading='lazy'
        className='absolute bottom-0 left-0 w-[100%] h-[5%]'
      />
      <div className="md:w-[98%] mx-auto my-12 text-center">
        <h2 className="text-center py-4 text-white relative">{rpdata?.labels?.general?.titleServices}</h2>
        <ButtonContent btnStyle={'one'} btnUrl={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`} btnName={'Order Now'} />
        <div className="flex mt-10 items-center justify-center">
          {/* {rpdata?.dbServices?.slice(0, 6)?.map((item, index) => {
            return (
              <ServicesHome
                key={index}
                bgImg={item.description[0].img}
                serviceName={item.name}
                serviceText={item.description[0].text.substring(0, 160) + "..."}
              />
            );
          })} */}


          <Swiper

            effect={'cards'}
            grabCursor={true}
            pagination={true}
            navigation={true}
            modules={[EffectCards, Pagination, Navigation]}
            className="mySwiper md:w-[600px] md:h-[750px]  w-[300px] h-[400px]"
          >
            {rpdata?.stock?.slice(0,2).map((item, index) => {
              return (
                <SwiperSlide key={index} className="h-full w-full bg-center bg-cover rounded" style={{ backgroundImage: `url("${item}")` }} ></SwiperSlide>
              )
            })
            }
          </Swiper>

        </div>
      </div>
    </div>
  );
}

export default ContentServicesHome;