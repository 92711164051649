import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'
import { ButtonContent } from '../global/boton/ButtonContent';

const ContentMenu = (cntService) => {
    const { rpdata } = useContext(GlobalDataContext);

    let classAnterior = "md:flex-row-reverse"

    return (
        <div className=''>
            {
                rpdata?.dbServices?.map((serv, index) => {
                    let classes = index % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"
                    let nuevaClass = classAnterior === "md:flex-row-reverse" ? "md:flex-row" : "md:flex-row-reverse"
                    classes = nuevaClass
                    classAnterior = classes;
                    return (
                        <div className='pb-20'>
                            <div key={index} className={`pb-3 mb-10 border-b-2 flex flex-col-reverse justify-between items-center ${classes}`}>
                                <div>
                                    <h2 className='text-center capitalize'>{serv.name}</h2>
                                    <ButtonContent btnLink='./Contact' btnName={'Order Now'} />
                                </div>

                                <img
                                    src={serv?.description[0]?.img}
                                    alt='services images'
                                    className='w-[150px] h-[150px] rounded-full shadow-lg'
                                />
                            </div>
                            <div className='flex flex-wrap justify-center items-center'>
                                {
                                    rpdata?.works?.map((item, index) => {
                                        if (item.service === serv.name) {
                                            return (
                                              <>
                                                  <div className="">
                                                    <div
                                                      className="grid gap-2 grid-cols-1 border border-black text-center w-[350px] h-[500px] p-4 m-6 rounded"
                                                      style={{
                                                        backgroundImage: `url("${"https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/imagenes_fondos%2Ffondo-oscuro.avif?alt=media&token=dfb10b47-fdf5-4dab-855a-fb82292efbfb"}")`,
                                                        backgroundSize: "cover",
                                                        backgroundPosition: 'center'
                                                      }}
                                                    >
                                                      <div className='justify-center items-center'>
                                                        <img
                                                          src={item.gallery?.[0] ? item.gallery?.[0] : rpdata?.dbPrincipal?.favicon}
                                                          className="w-[250px] h-[250px] rounded-full justify-center border-4 ml-8"
                                                          alt=""
                                                        />
                                                      </div>
                                                      <p className="font-semibold text-white text-lg">
                                                        {item.name}
                                                      </p>
                                                      <p className='font-semibold text-white'>
                                                        --------------------
                                                      </p>
                                                      <p className="font-semibold text-white">
                                                        {item.subdescription}
                                                      </p>
                                                      <p className="font-semibold text-white">
                                                        $ {item.description}
                                                      </p>
                                                      <button></button>
                                                    </div>
                                                  </div>
                                              </>
                                              // <div key={index} className='w-[90%] mx-auto flex-wrap flex items-center'>
                                              //     <p className='w-[75%] md:w-[80%] border-dotted border-b-2 border-black capitalize font-semibold text-[18px] mb-5'>{item.name}</p>
                                              //     <p className='w-[25%] md:w-[20%] text-end md:text-start text-[#8E101F] font-semibold'> ${item.description}</p>
                                              //     <p className='w-[100%] md:w-[70%] text-start md:text-start text-[#ffbf48] font-semibold mt-[-25px]'> {item.subdescription}</p>
                                              // </div>
                                            );
                                        }
                                        return null
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ContentMenu